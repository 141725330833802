// Imports
import 'lazysizes';
import reframe from 'reframe.js';

// Project imports
import BackButtons from '@js/modules/BackButtons';
import Carousels from '@js/modules/Carousels';
import ExternalLinks from '@js/modules/ExternalLinks';
import EntryFilters from '@js/modules/EntryFilters';
import Forms from '@js/modules/Forms';
import MobileNavigation from '@js/modules/MobileNavigation';
import Modal from '@js/modules/Modal';
import Navigation from '@js/modules/Navigation';
import SectionTitle from '@js/modules/SectionTitle';
import StatsCalc from '@js/modules/StatsCalc';

class Page {
    constructor() {
    }
    init() {
        // External links
        ExternalLinks.build();

        // Entry filters
        EntryFilters.init();

        setTimeout(() => {
            Forms.destroyForms();
            Forms.init();
        }, 2000);

        /**
         * Back button
         */
        BackButtons.init();

        /**
         * Section title nav
         */
        SectionTitle.init();

        /**
         * Carousels
         */
        Carousels.init();

        /**
         * Open external links in new tab
         */
        const extLinks = document.querySelectorAll('[data-external]');
        if (extLinks) {
            extLinks.forEach((item, i) => {
                item.addEventListener('click', (e) => {
                    let url = item.getAttribute('href');
                    window.open(url, '_blank').focus();
                });
            });
        }

        /**
         * Reframe
         */
        reframe('.reframe');

        /**
         * Modal
         */
        Modal.init();

        /**
         * Section carousels
         */
        window.APP.modules.SectionCarousels.init();

        /**
         * Line hover
         */
        const lineHoverEls = document.querySelectorAll('.line-hover');
        lineHoverEls.forEach((el, i) => {
            el.addEventListener('mouseover', () => {
                el.classList.add('_hover');
            });

            el.addEventListener('mouseout', () => {
                el.classList.remove('_hover');
            });
        });

        /**
         * To top button
         */
        const toTopBtns = document.querySelectorAll('.to-top');
        toTopBtns.forEach(btn => {
           btn.addEventListener('click', (e) => {
               window.scrollTo({
                   top: 0,
                   left: 0,
                   behavior: 'auto',
               });
           });
        });
    }
}

export default new Page();
